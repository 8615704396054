<div   style="height:25px; overflow: hidden"
       class="absolute top-0 seo-container text-center z-0">
  <h1>{{dataPage.seoTexts.h1}}</h1>
  <h2>{{dataPage.seoTexts.h2}}</h2>
  <h3>{{dataPage.seoTexts.h3}}</h3>
  <p>{{dataPage.seoTexts.text}}</p>
</div>
<app-contacts-left-artas
  [showForm]="false"
  >
</app-contacts-left-artas>
<app-navbar-top-artas
  [routeHome]="'/'"
  [logoText]="'ARTAS.ORG.UA'"
></app-navbar-top-artas>
<app-header
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h2"
></app-header>
<div class="bg-color-custom">
  <app-common-artas
    [dataPage]="dataPage"
    (onClickRoute)="setSelect($event)"
  ></app-common-artas>
</div>
