<form class="bg-color-custom w-full max-w-sm" #formRef="ngForm">
  <div class="flex items-center border-b border-teal-500 py-2">

    <img *ngIf="selectModel.srcPreview"
         class="-mt-8 inline-flex rounded-full"
         [src]="selectModel.srcPreview"
         width="64"
         height="64"
         alt="User"
    />

    <div class="flex flex-col w-full">
      <input class="appearance-none bg-transparent border-none w-full text-gray-100 mr-3 py-1 px-2 leading-tight focus:outline-none"
             type="text"
             placeholder="Введіть телефон"
             aria-label="Введіть телефон"
             minlength="5"
             name="phone"
             [(ngModel)]="form.phone"
             required
             #phoneRef="ngModel"
      >

      <!-- Повідомлення про помилку -->
      <div *ngIf="phoneRef.invalid && phoneRef.touched" class="text-red-500 text-xs mt-1">
        Будь ласка, введіть телефон.
      </div>
    </div>

    <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
            type="button"
            [disabled]="formRef.invalid"
            (click)="submit()"
    >
      Замовити
    </button>

  </div>

  <input class="block appearance-none bg-transparent border-none w-full text-gray-100 mr-3 py-1 px-2 leading-tight focus:outline-none"
         type="text"
         placeholder="Введіть коментар"
         aria-label="Введіть коментар"
         minlength="5"
         name="comment"
         [(ngModel)]="form.comment"
  >

  <!-- Після відправки -->
  <div *ngIf="isSubmitted" class="text-green-400 mt-4 text-center text-lg font-semibold">
    Відправлено!
  </div>
</form>
