<div class="z-50 fixed"
[style.bottom.px]="bottom"
[style.left.px]="left"
>
  <div [ngClass]="classBg"
    class="relative ">
    <!--<button
      class=" text-white focus:ring-4  transform  active:scale-75 transition-transform  text-center  bg-indigo-600 py-1 px-1 rounded shadow-lg"
    >Відгуки
    </button>-->
    <nav class="grid grid-cols-2 gap-0.5 border-t border-gray-200  p-0.5 shadow-lg backdrop-blur-lg min-h-[auto] min-w-[48px] flex-col rounded-lg border">
      <ng-container *ngIf="showContacts">
        <ng-container *ngFor="let item of myData.messengers; let i = index">
          <a *ngIf="item.href" [target]="item.target"
             [href]="item.href"
             (click)="item.command(item, i)"
             class="z-30 flex aspect-square min-h-[30px] w-[30px] flex-col items-center justify-center gap-0.5 rounded-md p-0.5  text-amber-600"
          >
            <img *ngIf="item.srcPreview"
                 [src]="item.srcPreview" class="h-[30px] w-[30px]"
                 [alt]="item.label">
            <i *ngIf="item.icon && !item.srcPreview"
               style="font-size:8px" class="mx-0.5"
               [class]="item.icon"
            ></i>

            <span *ngIf="item.label" class="text-[8px]">{{item.label}}</span>
          </a>
        </ng-container>
      </ng-container>
    </nav>
  </div>
</div>
