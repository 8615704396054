// online-status.component.ts

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {NgIf} from '@angular/common';
import {CdServices} from "../../../../services/common/cd.services";
import {
  clearCurrency,
  extractCost,
  getDiscount
} from "../../../../traits/string-tools";
@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  standalone:true,
  imports:[NgIf]
})
export class CostComponent implements OnInit {
  @Input () cost:string;
  @Input () showDiscount:boolean = false;
  @Input () clearCurrency:boolean = false;
  @Input () discount:number = 20;
  @Output() onOnlineStatus = new EventEmitter<boolean>();

  constructor(private cd: CdServices) { }

  ngOnInit(): void {
    this.cost = this.clearCurrency?clearCurrency(this.cost):this.cost;
  }

  protected readonly getDiscount = getDiscount;
  protected readonly extractCost = extractCost;
}
