<section class="bg-color-custom" >

  <div class="container   ">
    <p
       class="font-select">
      {{card.name}}
    </p>
<div class="w-full text-center">
  <div #swiperContainerRef
    [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
    class=" swiper mySwiper bg-[length:50px_50px] bg-opacity-10  bg-repeat  justify-center items-center"
  >
    <div class="swiper-wrapper">
      <ng-container
        *ngFor="let  card  of  photosBox; let last = last;">
          <div class="swiper-slide w -[300px] h-[300px]">
            <div
              class=" flex flex-col justify-center items-center md:flex-row md:items-start space-y-8 md:space-y-0 md:space-x-4 lg:space-x-8 max-w-6xl w-11/12 mx-auto">
              <div class="w-full md:w-1/2 max-w-md border border-palette-lighter bg-white rounded shadow-lg">
                <div class="relative">

                  <div class="max-w-md mx-auto rounded-md overflow-hidden shadow-md hover:shadow-lg">


                    <div class="relative">
                      <img class="w-full"
                           [src]=card.srcPreview
                           [alt]=card.name
                      />
                      <div *ngIf="card.name"
                        class="absolute top-0 right-0 backdrop-blur-md bg-slate-800 text-white px-2 py-1 m-2 rounded-md text-sm font-medium">
                        {{ card.name }}
                      </div>
                      <!--<div
                        class="absolute top-0 left-0 bg-red-500 text-white px-2 py-1 m-2 rounded-md text-sm font-medium">
                        Хочу подібний!
                      </div>-->

                      <div *ngIf="card.text"
                        class="h-min-[300px] backdrop-blur-md bg-slate-900/60 absolute top-[50px] text-slate-300 px-2 py-1 m-2 rounded-md text-sm font-medium">
                        {{card.text}}

                      </div>
                    </div>

                  </div>
                </div>
<!--                <div class="relative flex border-t border-palette-lighter">-->

<!--                  <div-->
<!--                    class="flex space-x-1 w-full overflow-auto border-t border-palette-lighter"-->
<!--                  >-->
<!--                    <ng-container *ngFor="let itemFavorite of itemCardBudget.favorites">-->
<!--                      <button class="relative w-40 h-32 flex-shrink-0 rounded-sm "-->
<!--                              (click)="itemCardBudget.srcPreview = portretInks.srcPreview"-->
<!--                      >-->
<!--                        <img [src]=portretInks.srcPreview [alt]=itemFavorite.name-->
<!--                             class=""-->
<!--                        />-->
<!--                      </button>-->
<!--                    </ng-container>-->
<!--                  </div>-->
<!--                </div>-->
              </div>

            </div>
          </div>
        </ng-container>
    </div>
  </div>

</div>
  </div>
</section>
