import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta, private title: Title) { }

  // Установка мета-тегов
  setMetaTags(title: string, description: string, keywords: Array<string>, image:string ='https://server-2.artas.org.ua/Server/images/cgh2aufci_1722080743100_.jpg') {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'keywords', content: keywords.join(';') });
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:url', content: `https://artas.org.ua/`});

    // Для Twitter
    this.meta.updateTag({ name: 'twitter:card', content: image});
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: image});


    // this.meta.updateTag({ name: 'robots', content: robots });
  }

  // Установка Canonical URL
  setCanonicalUrl(url: string) {
    this.meta.updateTag({ rel: 'canonical', href: url });
  }

  // Установка Open Graph мета-тегов
  setOpenGraphTags(title: string, description: string, image: string, url: string, type: string) {
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ property: 'og:type', content: type });
  }

  // Установка Twitter мета-тегов
  setTwitterTags(title: string, description: string, image: string, username: string) {
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: image });
    this.meta.updateTag({ name: 'twitter:creator', content: username });
  }
}
