

<div style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{ dataPageArtParty.seoTexts.h1 }}</h1>
  <h2>{{ dataPageArtParty.seoTexts.h2 }}</h2>
  <h3>{{ dataPageArtParty.seoTexts.h3 }}</h3>
  <p>{{ dataPageArtParty.seoTexts.text }}</p>
</div>
<p-overlayPanel #opShowContacts>
  <div
    class="relative bg-color-custom  "
  >
    <nav
      class="flex shrink-0 grow-0 justify-around gap-4 border-t border-gray-200 bg-color-custom/50 p-1 shadow-lg backdrop-blur-lg   min-h-[auto] min-w-[64px] flex-col rounded-lg border"
    >
      <ng-container *ngFor="let item of myData.menuTop.concat(myData.menuBottom).concat(myData.phones);let i = index">

        <a *ngIf="item.href" [target]="item.target"
           [href]="item.href"
           (click)="item.command(item,i)"
           class=" z-30 flex aspect-square min-h-[60px] w-[60px] flex-col items-center justify-center gap-1 rounded-md p-3 bg-color-custom text-amber-600"
        >
          <img *ngIf="item.srcPreview"
               [src]="item.srcPreview" class="h-[60px] w-[60px]"
               [alt]="item.label">
          <i *ngIf="item.icon && !item.srcPreview"
             style="font-size:15px" class=" mx-1"
             [class]="item.icon"
          ></i>

          <span *ngIf="item.label">{{ item.label }}</span>
        </a>

      </ng-container>
    </nav>
  </div>
</p-overlayPanel>
<div class="  leading-normal tracking-normal text-color-custom bg-color-custom"
   >
  <!--Nav-->
  <nav id="header" class="fixed w-full z-30 top-0 text-color-custom bg-slate-600">
    <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
      <div class="pl-4 flex items-center">
        <a
          class="toggleColour text-color-custom no-underline hover:no-underline font-select text-[20px] "
          href="#"
        >
          ART-PARTY
        </a>
        <div class="z-20 absolute  bottom-[5px] left-[50px] text-color-custom text-[10px]">
          15 років малюю для Вас!
        </div>
      </div>
      <div class="col-start-10 col-end-12  flex justify-end items-center mr-3">
        <div class="" id="navbar-sticky ">
          <ul class=" flex items-center justify-between w-full text-sm">

            <a class="flex items-center">
              <img [src]="myData.personal.flagSrc"
                   class=" animate-bounce animate-infinite animate-duration-[2000ms] w-[15px] h-[10px ] mr-3" alt="">
              <span
                class="   text-color-custom self-center text-[12px]  whitespace-nowrap">
        {{ myData.personal.cityCountry }}
      </span>


            </a>
          </ul>
          <div class="z-20 absolute font-bold  bottom-[5px] right-[5px] text-amber-200 text-[10px]">

            <!--     <span *ngIf="basketServices.statusSendMessege" >
                     Заявка відправленна!
                    <i [class]="telegramIcon"></i>
                 </span>-->
            <!--  <span *ngIf="!basketServices.statusSendMessege" >
               Найближчим часом звяжусь з Вами!
               <i [class]="favoriteIcon"></i>-->
            <!--          </span>-->

          </div>

        </div>

      </div>
    </div>
    <hr class="border-b border-white opacity-25 my-0 py-0"/>
  </nav>
  <!--Hero-->
  <div class=" pt-24">
    <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
      <!--Left Col-->
      <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
        <p class="uppercase tracking-loose w-full"></p>
        <p
           class="font-select my-4 text-3xl leading-tight">
          Шаржі по фото на вашому святі
        </p>
        <p   class="leading-normal text-md mb-8 text-slate-500">
          Ви можете замовити карикатури чи шаржі по фото і подарувати гостям
        </p>
        <button #targetShowContacts (click)="opShowContacts.toggle($event,targetShowContacts);"

                class=" font-select mx-auto lg:mx-0 hover:underline bg-color-custom  text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
          Контакты
        </button>
      </div>
    </div>
    <div class="area" >
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div >
  </div>

  <ng-container *ngFor="let itemCardAddition of dataPageArtas.getCardsAdditions() ">
  <app-card-section *ngIf="itemCardAddition"
    [card]="itemCardAddition"
    (onSelect)="setSelect($event)"
    >

  </app-card-section>
  </ng-container>
  <div class="w-full md:w-3/5 py-6 text-center">
    <div
      [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
      class=" swiper mySwiper bg-[length:50px_50px] bg-opacity-10  bg-repeat min-h-screen flex justify-center items-center"
    >
      <div class="swiper-wrapper">
        <ng-container
          *ngFor="let  card  of  dataPageArtas.getCardsMessenges().concat(dataPageArtParty.getCardsMessenges()); let last = last;">
          <ng-container *ngIf="card.sw === true || last === true ">
            <div class="swiper-slide">
              <!--        <app-card-artas
                        [card]="card"

                      ></app-card-artas>-->

              <p class="w-full my-2 text-md leading-tight text-center text-color-custom ">
                {{ card.name }}
              </p>

              <p-overlayPanel #opOrderByImage>
                <app-order-by-image-form *ngIf="showOrderByCard "
                                         [selectModel]="card"
                                         (onClose)="showOrderByCard = false"
                ></app-order-by-image-form>
              </p-overlayPanel>
              <img [src]="card.srcPreview"/>
              <button (click)="setSelect(card)"
                      class=" left-1 absolute bottom-1 z-20  focus:ring-4  transform  active:scale-75 transition-transform mt-4 text-xl w-90 text-center  bg-color-custom py-1 px-1 rounded-lg shadow-lg"
              >
                                   <span class="ml-1 text-xl text-color-custom ">
                  <i [class]="photosIcon"></i>
                                     Перейти
                </span>
              </button>
              <!--<button #targetOrderByImage
                      (click)=" showOrderByCard =true; opOrderByImage.toggle($event,targetOrderByImage);"
                      class=" right-1 absolute bottom-1 z-20 focus:ring-4  transform  active:scale-75 transition-transform mt-4 text-xl w-90 text-center  bg-color-custom py-1 px-1 rounded-lg shadow-lg"
              >
                  <span class="ml-1 text-xl text-color-custom ">
                    <i [class]="basketIcon"></i>
                    <span>({{card.cost?card.cost:'договірна'}})</span>
                  </span>
              </button>-->
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

  </div>
  <section class="bg-color-custom  border-b py-8">
    <div class="container max-w-5xl mx-auto m-8">
      <p style="font-family: peace_sans,serif;"
         class="w-full m ease-in-out text-2xl leading-tight text-center bg-color-custom text-color-custom rounded-full my-6 py-4 px-8 shadow-lg">
        Виликі знижки та супер пропозиції :
      </p>

      <div [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
        class="mx-auto max-w-6xl bg-[length:50px_50px] bg-opacity-10  bg-repeat ">
        <ng-container *ngFor="let itemCardBudget of dataPageArtas.getCardsBudget()">
          <div
            class="  mb-6 flex flex-col justify-center items-center md:flex-row md:items-start space-y-8 md:space-y-0 md:space-x-4 lg:space-x-8 max-w-6xl w-11/12 mx-auto">
            <div class="w-full md:w-1/2 max-w-md border border-palette-lighter bg-white rounded shadow-lg">
              <div class="relative h-96">

                <div class="max-w-md mx-auto rounded-md overflow-hidden shadow-md hover:shadow-lg">
                  <div class="relative">
                    <img class="w-full"
                         [src]=itemCardBudget.srcPreview
                         [alt]=itemCardBudget.name
                    />
                    <div
                      class="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 m-2 rounded-md text-sm font-medium">
                      {{ itemCardBudget.action }}
                    </div>
                    <div
                      class="absolute top-0 left-0 bg-red-500 text-white px-2 py-1 m-2 rounded-md text-sm font-medium">
                      Хочу подібний!
                    </div>
                  </div>

                </div>
              </div>
              <div class="relative flex border-t border-palette-lighter">

                <div
                  class="flex space-x-1 w-full overflow-auto border-t border-palette-lighter"
                >
                  <ng-container *ngFor="let itemFavorite of itemCardBudget.favorites">
                    <button class="relative w-40 h-32 flex-shrink-0 rounded-sm "
                            (click)="itemCardBudget.srcPreview = portretInks.srcPreview"
                    >
                      <img [src]=portretInks.srcPreview [alt]=itemFavorite.name
                           class=""
                      />
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </div>
      <!--      <div class="flex flex-wrap">
              <div class="w-5/6 sm:w-1/2 p-6">
                <p style="font-family: peace_sans,serif;" class="text-2xl text-color-custom leading-none mb-3">
                  {{ portretInks.name }}
                </p>
                <p style="font-family: peace_sans,serif;" class="text-color-custom mb-8">
                  {{ portretInks.desc }}
                  <br/>
                  <br/>
                </p>

              </div>
              <div class="w-full sm:w-1/2 p-6">
                <img [src]="portretInks.srcPreview"/>
                <button (click)="setSelect(portretInks)"
                        class="mx-auto lg:mx-0 hover:underline bg-color-custom text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Перейти  - <app-cost
                  *ngIf="portretInks.action"
                  [cost]="portretInks.action"
                  [showDiscount]="false"
                  [clearCurrency]="false"
                ></app-cost>
                </button>
              </div>

            </div>
            <div class="flex flex-wrap flex-col-reverse sm:flex-row">
              <div class="w-full sm:w-1/2 p-6 mt-6">
                <img [src]="characterInks.srcPreview"/>
                <button (click)="setSelect(characterInks)"
                        class="mx-auto lg:mx-0 hover:underline bg-color-custom text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Перейти -  - <app-cost
                  *ngIf="characterInks.action"
                  [cost]="characterInks.action"
                  [showDiscount]="false"
                  [clearCurrency]="false"
                ></app-cost>
                </button>
              </div>

              <div class="w-full sm:w-1/2 p-6 mt-6">
                <div class="align-middle">
                  <p style="font-family: peace_sans,serif;" class="text-2xl text-color-custom leading-none mb-3">
                    {{ characterInks.name }}
                  </p>
                  <p style="font-family: peace_sans,serif;" class="text-color-custom mb-8">
                    {{ characterInks.desc }}
                    <br/>
                    <br/>
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-5/6 sm:w-1/2 p-6">
                <p style="font-family: peace_sans,serif;" class="text-2xl text-color-custom leading-none mb-3">
                  {{ characterInksColor.name }}
                </p>
                <p style="font-family: peace_sans,serif;" class="text-color-custom mb-8">
                  {{ characterInksColor.desc }}
                  <br/>
                  <br/>
                </p>
              </div>
              <div class="w-full sm:w-1/2 p-6">
                <img [alt]="characterInksColor.name" [src]="characterInksColor.srcPreview"/>
                <button (click)="setSelect(characterInksColor)"
                        class="mx-auto lg:mx-0 hover:underline bg-color-custom text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Перейти - <app-cost
                  *ngIf="characterInksColor.action"
                  [cost]="characterInksColor.action"
                  [showDiscount]="true"
                  [clearCurrency]="false"
                ></app-cost>
                </button>
              </div>

            </div>
            <div class="flex flex-wrap flex-col-reverse sm:flex-row">
              <div class="w-full sm:w-1/2 p-6 mt-6">
                <img [src]="siluet.srcPreview"/>
                <button (click)="setSelect(siluet)"
                        class="mx-auto lg:mx-0 hover:underline bg-color-custom text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Перейти
                  <app-cost
                    *ngIf="siluet.action"
                    [cost]="siluet.action"
                    [showDiscount]="true"
                    [clearCurrency]="false"
                  ></app-cost>
                </button>
              </div>
              <div class="w-full sm:w-1/2 p-6 mt-6">
                <div class="align-middle">
                  <p style="font-family: peace_sans,serif;" class="text-2xl text-color-custom leading-none mb-3">
                    {{ siluet.name }}
                  </p>
                  <p style="font-family: peace_sans,serif;" class="text-color-custom mb-8">
                    {{ siluet.desc }}
                    <br/>
                    <br/>
                  </p>
                </div>
              </div>
            </div>-->
    </div>
  </section>
  <section [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
           class="bg-color-custom  border-b py-8 bg-[length:50px_50px] bg-opacity-10  bg-repeat ">
    <div class="container mx-auto flex flex-wrap pt-4 pb-12">
      <h2 class="w-full my-2 text-5xl leading-tight text-center text-color-custom">
        Перелік усіх послуг:
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto bg-color-custom w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>

    </div>
  </section>

  <section [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
           class="bg-color-custom  border-b py-8 bg-[length:50px_50px] bg-opacity-10  bg-repeat ">
    <div class="container mx-auto px-2 pt-4 pb-12 text-color-custom">
      <p style="font-family: peace_sans,serif;"
         class="w-full my-2 text-5xl leading-tight text-center text-color-custom">
        Вартість
      </p>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto bg-color-custom w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
        <div class="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-color-custom  mt-4">
          <div class="flex-1 bg-color-custom  text-color-custom rounded-t rounded-b-none overflow-hidden shadow">
            <div style="font-family: peace_sans,serif;"
                 class="p-8 text-3xl text-center border-b-4">
              Приїзд
            </div>
            <ul class="w-full text-center text-sm">
              <ng-container
                *ngFor="let  card  of  dataPageArtParty.getCardsMessenges(); let last = last;">
                <ng-container *ngIf="card.sw === true || last === true ">
                  <li *ngIf="card.name" class=" border-b py-4">
                    {{ card.name }}

                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
          <div class="flex-none mt-auto bg-color-custom  rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div class="w-full pt-6 text-3xl text-color-custom text-center">
              Договірна
              <span class="text-base">/ за ходину</span>
            </div>
            <div class="flex items-center justify-center">
              <button [routerLink]="'/'"
                      class="mx-auto lg:mx-0 hover:underline bg-color-custom text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                Зараз працюю тільки по фото
              </button>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-color-custom  mt-4 sm:-mt-6 shadow-lg z-10">
          <div class="flex-1 bg-color-custom  rounded-t rounded-b-none overflow-hidden shadow">
            <div style="font-family: peace_sans,serif;"
                 class="w-full p-8 text-3xl text-color-custom  text-center">По фото
            </div>
            <div class="h-1 w-full bg-color-custom my-0 py-0 rounded-t"></div>
            <ul class="w-full text-center text-color-custom  text-sm">
              <ng-container
                *ngFor="let  card  of  dataPageArtas.getCardsMessenges(); let last = last;">
                <ng-container *ngIf="card.sw === true || last === true ">
                  <li *ngIf="card.name" class=" border-b py-4">
                    {{ card.name }}
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
          <div class="flex-none mt-auto bg-color-custom  rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div class="w-full pt-6 text-3xl text-color-custom  text-center">
              від 100 грн
              <span class="text-base">/ за героя</span>
            </div>
            <div class="flex items-center justify-center">
              <button (click)="setSelect(dataPageArtas.getCardsMessenges()[0])"
                      class="mx-auto lg:mx-0 hover:underline bg-color-custom text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                Перейти
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-color-custom  mt-4">
          <div class="flex-1 bg-color-custom  text-color-custom rounded-t rounded-b-none overflow-hidden shadow">
            <div style="font-family: peace_sans,serif;" class="p-8 text-3xl text-center border-b-4">
              Передзамовлення на свята та заходи по фото
            </div>
            <ul class="w-full text-center text-sm">
              <ng-container
                *ngFor="let  card  of  [portretInks,characterInks,characterInksColor]; let last = last;">
                <ng-container *ngIf="card.sw === true || last === true ">
                  <li *ngIf="card.name" class="border-b py-4">{{ card.name }}</li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
          <div class="flex-none mt-auto bg-color-custom  rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div class="w-full pt-6 text-3xl text-color-custom text-center">
              від 500 грн
              <span class="text-base">/від ТЗ </span>
            </div>
            <div class="flex items-center justify-center">
              <button (click)="setSelect(dataPageArtas.getCardsMessenges()[0])"
                      class="mx-auto lg:mx-0 hover:underline bg-color-custom text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                Перейти
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container mx-auto text-center py-6 mb-12">
    <!--  <h2 class="w-full my-2 text-1xl leading-tight text-center text-color-custom">
        <app-action [showCost]="false"></app-action>
      </h2>-->
    <div class="w-full mb-4">
      <div class="h-1 mx-auto bg-color-custom  w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
    </div>
    <h3 class="my-4 text-3xl leading-tight">
      Завжди до ваших послуг
    </h3>
    <button #targetShowContacts2 (click)="opShowContacts.toggle($event,targetShowContacts2);"
            class="mx-auto lg:mx-0 hover:underline bg-color-custom  text-color-custom rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
      Контакти
    </button>
  </section>
  <footer class="bg-color-custom ">
  </footer>


</div>
