import {Injectable} from '@angular/core';

import {ApiServices} from '../api/api.services';
import {timeOut} from "../../traits/datetime-tools";

@Injectable({
  providedIn: 'root',
})


export class ServerSiteServices {


}
