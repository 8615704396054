import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {photosIcon} from "../../../../../traits/icons-tools";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {OrderByImageFormComponent} from "../../../page-artas/order-by-image-form/order-by-image-form.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {BoxServices} from "../../../../../services/box/box.services";
declare const Swiper;

@Component({
  selector: 'app-card-section',
  templateUrl: './card-section.component.html',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    OrderByImageFormComponent,
    OverlayPanelModule,
  ],

})
export class CardSectionComponent implements OnInit {
  @ViewChild('swiperContainerRef')
  swiperContainerRef: any;
  @ViewChild('swiperPaginationRef')
  swiperPaginationRef: any;
  @Input() card: any;
  public photosBox:any[]
  @Output() onSelect = new EventEmitter();
  protected readonly photosIcon = photosIcon;


  constructor( public boxServices: BoxServices,) {}

  ngOnInit() {

  }
  ngAfterViewInit(){



    this.initBox(this.card)
    this.initSwiper();
  }
  initSwiper(){

    console.log(this.card)
    // let   swiperNativeElement =  this.swiperContainerRef.nativeElement
    //   let   swiperPaginationElement =  this.swiperPaginationRef.nativeElement
      const swiper = new Swiper('.mySwiper', {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        },
        // pagination: {
        //   el: swiperPaginationElement,
        // },
      });

  }

  public initBox(card){
    let boxId:any = this.boxFileName(card)
    this.boxServices.findCardBoxById(boxId,
      (responseBox: any): void => {
        this.photosBox =responseBox.photos
        console.log(responseBox,'responseBox')
      });
  }
  boxFileName(model: any) {
    let boxId = model.id + '_' + model.createAt;
    return boxId.replace(/\s/g, "");
  }

  public setSelect(card: any) {
    this.onSelect.emit(card)
  }
}

