<section [id]="card?.id"
         [class]="bgClass"
         class=" animate-fade-right animate-once m-4 max-w-sm  px-6 pt-6 pb-2 rounded-xl shadow-lg transform  hover:scale-100 transition duration-500"
>
  <h3 [style.color]="card?.color"
      style="font-family: peace_sans,serif;"
      class="mb-3 text-md tracking-wide text-amber-600">
      <span *ngIf="card.name">
         {{ card?.name }}
      </span>

    <span *ngIf="card?.srcIcon"
          [class]="bgClass +' '+ borderClass"
          class="  absolute -top-3 right-0 rounded-full  border-4 ">
         <img
           class="rounded-lg w-[50px] h-[50px]"
           [src]="card?.srcIcon"
           [alt]="card?.name"
         >
       </span>
    <div class=" z-50 -left-[30px] fixed top-[200px]  ">

      <div class="flex flex-col items-start space-y-2 p-2">
        <div *ngFor="let label of labels; let i = index" class="flex items-center">
          <span class="text-zinc-400 mr-2">&#10003;</span> <!-- Checkmark glyph -->
          <div class="text-[10px] bg-opacity-90 bg-zinc-800 text-zinc-400 shadow-sm rounded-md px-1 py-1 transform transition duration-300 ease-out opacity-90">
            {{ label }}
          </div>
        </div>
      </div>

    </div>
  </h3>
  <app-action *ngIf="cd.showActionsSales  || card?.action "
              [card]="card"
  ></app-action>


  <div class="relative">
    <img *ngIf="card?.srcPreview && card?.name"
         class="w-full rounded-xl"
         [src]="card?.srcPreview"
         [alt]="card?.name"
         [style.border]="'2px solid ' + card?.color +' '"
    />

    <p *ngIf="card?.status"
       [class]="'bg-amber-300'"
       class="absolute top-0 text-zinc-800 font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg">
    <span *ngIf="card?.status" class="line-through text-dark-600 font-bold">
      {{ card?.status }}
    </span>
    </p>

    <p *ngIf="!card?.costDiscount"
       [class]="bgClass"
       class=" absolute top-0 right-0 bg-amber-300 text-zinc-800 font-semibold py-1 px-3 rounded-tr-lg rounded-bl-lg"
    >
    </p>
    <p *ngIf="card?.costDiscount"
       [class]="'bg-amber-300'"
       class=" absolute top-0 right-0 bg-amber-300 text-zinc-800 font-semibold py-1 px-3 rounded-tr-lg rounded-bl-lg"
    >
        <span>
          <span>{{ card?.costDiscount }}</span>
        </span>
    </p>
    <app-gif-generator *ngIf="card.favorites "
                       [photos]="card?.favorites">
    </app-gif-generator>

  </div>
  <app-text-slice *ngIf="card?.desc"
    [text]="card?.desc"
  ></app-text-slice>

  <ng-container>
    <div class="my-4">
      <div *ngIf="card?.cat"
           class="flex space-x-1 items-center"
      >
        <app-svg-icon></app-svg-icon>

        <p class="text-zinc-400">{{ card?.cat }}</p>
      </div>
      <div *ngIf="card?.duration"
           class="flex space-x-1 items-center"
      >
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
            <i [class]="clockIcon"></i>
          </span>
        <p class="text-zinc-400">{{ card?.duration }}</p>
      </div>

      <div *ngIf="card?.level" class="flex space-x-1 items-center">
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
           <i [class]="levelIcon"></i>
          </span>
        <p class="text-zinc-400">
          <span>{{ card?.level }}</span>
        </p>
      </div>

      <div *ngIf="card?.additionally" class="flex space-x-1 items-center">
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
              <i [class]="folderIcon"></i>
          </span>
        <p class="text-zinc-400"> {{ card?.additionally }}</p>
      </div>
      <div *ngIf="card?.info" class="font-bold text-xl mb-2">
        <p class="text-amber-500 text-base">{{ card?.info }}</p>
      </div>
      <p-overlayPanel #opOrderByImage>
        <app-order-by-image-form *ngIf="showOrderByCard "
                                 [typeOrder]="'Перейшли з послуги! -  ' + card?.name"
                                 [selectModel]="card"
                                 (onClose)="showOrderByCard = false"
        ></app-order-by-image-form>
      </p-overlayPanel>
      <div class="flex justify-center">
        <button  [routerLink]="card?.routerLink"
                 (click)=" onClickRoute.emit(card);"
                 class="focus:ring-4  transform  active:scale-75 transition-transform mt-4 w-90 text-center  bg-indigo-600 py-2 px-2 rounded-l-lg shadow-lg"
        >

          <span
            class=" font-mono ml-1 text-sm text-indigo-200  tracking-normal ">{{ 'Галерея робіт' }}</span>
        </button>
        <button #targetOrderByImage
                (click)=" showOrderByCard =true; opOrderByImage.toggle($event,targetOrderByImage);"
                class="focus:ring-4 ml-1 transform scale-125 active:scale-150 text-center transition-transform mt-4 text-xl w-10 text-white  bg-[#610A2BFF] py-2 px-2 rounded-r-lg shadow-lg"
        >
      <span class="ml-1 text-xl text-white ">
        <i [class]="envelopeIcon"></i>
      </span>

        </button>
      </div>
      <div *ngIf="card?.tags " class="mt-2 overflow-x-auto">
        <div class="flex space-x-1 items-center">
          <ng-container *ngFor="let  tagItem  of  card?.tags;">
            <div [class]="bgGlobalClass"
                 class="ml-4   text-[10px] inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full"
            >
              <span class="text-amber-300 "><i [class]="tagIcon "></i></span>
              <span class="mr-1 text-zinc-300">{{ tagItem?.name }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</section>
